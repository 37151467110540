<template>
  <footer class="site-footer slanted-footer">
    <a href="#top" class="smoothscroll scroll-top">
      <span class="icon-keyboard_arrow_up"></span>
    </a>

    <div class="container">
      <div class="row mb-5">
        <div class="col-12 col-md-6 col-lg-3 mb-4 text-center">
          <img v-if="info && info.kontakt && info.kontakt.icon"
            :src="info.kontakt.icon"
            alt="nakladne auto"
            style="opacity: 0.5; width: 96px; height: 96px"
          />
          <h3>Kontakt</h3>
          <ul class="list-unstyled" v-if="info && info.kontakt">
            <li>{{ info.kontakt.meno }}</li>
            <li v-if="info.kontakt.tel">
              Telefón:
              <a :href="'callto:' + info.kontakt.tel">
                {{ info.kontakt.tel }}
              </a>
            </li>
            <li v-if="info.kontakt.email">
              E-mail:
              <a :href="'mailto:' + info.kontakt.email">
                {{ info.kontakt.email }}
              </a>
            </li>
            <li v-if="info.kontakt.ico">
              IČO: {{ info.kontakt.ico }}
            </li>
          </ul>
          <div class="footer-social">
            <a href="https://www.facebook.com/prirodnykamen" target="_blank"
              ><span class="icon-facebook"></span
            ></a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4 text-center">
          <img v-if="info && info.prevadzka && info.prevadzka.icon"
            :src="info.prevadzka.icon"
            alt="nakladne auto"
            style="opacity: 0.5; width: 96px; height: 96px"
          />
          <h3>Prevádzka</h3>
          <ul class="list-unstyled" v-if="info">
            <li>{{ info.prevadzka.ulica }} {{ info.prevadzka.cislo }}</li>
            <li>{{ info.prevadzka.mesto }}</li>
            <li v-if="info.prevadzka.gps">
              GPS:
              <a
                :href="'https://www.google.sk/maps/place/' + info.prevadzka.gps"
                target="_blank"
              >
                {{ info.prevadzka.gps }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mb-4 text-center">
          <img v-if="info && info.otvaraciaDoba && info.otvaraciaDoba.icon"
            :src="info.otvaraciaDoba.icon"
            alt="nakladne auto"
            style="opacity: 0.5; width: 96px; height: 96px"
          />
          <h3>Otváracia doba</h3>
          <ul class="list-unstyled" v-if="info">
            <li v-for="den in info.otvaraciaDoba.dni" :key="den.order">
              {{ den.nazov }} : {{ den.zaciatok }} - {{ den.koniec }}
            </li>
            <li>Obedná pauza: {{ getPauza() }}</li>
          </ul>
        </div>
        <div
          class="col-12 col-md-6 col-lg-3 mb-4 text-center"
          v-if="info && info.benefity"
        >
          <img
            :src="info.benefity.icon"
            alt="nakladne auto"
            style="opacity: 0.5; width: 96px; height: 96px"
          />
          <div v-for="benefit in info.benefity.list" :key="benefit.title">
          <h3>{{ benefit.title }}</h3>
          <p>{{ benefit.content }}</p>
          </div>
        </div>
      </div>
      <div class="row text-center" v-if="info && info.menu">
        <div class="col-12">
          <ul id="bottomMenu">
             <li v-for="l in info.menu" :key="l.to" >
                <router-link :to="{ name: l.to }">{{l.title}}</router-link>
              </li>
          </ul>
        </div>
      </div>

      <div class="row text-center">
        <div class="col-12">
          <p class="copyright">
            <small class="block">
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              Copyright &copy; {{ year }} All rights reserved | This template is
              made with
              <i class="icon-heart text-danger" aria-hidden="true"></i> by
              <a href="https://colorlib.com" target="_blank">Colorlib</a>
              <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
            </small>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
      info: null,
    };
  },
  async mounted() {
    this.$axios
      .get("/data/info.json")
      .then((response) => (this.info = response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    getPauza() {
      if (
        this.info === undefined &&
        this.info.otvaraciaDoba === undefined &&
        this.info.otvaraciaDoba.pauza === undefined
      )
        return "";
      return (
        this.info.otvaraciaDoba.pauza.zaciatok +
        "-" +
        this.info.otvaraciaDoba.pauza.koniec
      );
    },
  },
};
</script>


<style scoped>
#bottomMenu {
  list-style: none;
}

#bottomMenu li {
  display: inline;
  padding: 0 10px;
  border-left: solid 1px black;
}

#bottomMenu li:first-child {
  border-left: none;
}
</style>